/* process.css */
.timeline {
  position: relative;
  padding: 20px 0;
  margin-bottom: 150px;
}

.timeline::before {
  content: '';
  position: absolute;
  width: 4px;
  background-color: #ddd;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -2px;
}

.timeline-step {
  position: relative;
  width: 100%;
  margin: 20px 0; /* Adjust margin to reduce gaps */
}

.timeline-step:nth-child(odd) {
  right: 75%;
  margin-bottom: -100px;
}

.timeline-step:nth-child(even) {
  left: 75%;
  margin-bottom: -100px;
}

.timeline-content {
  padding: 10px 120px;
  background: #000000;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.timeline-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 10px;
}

.timeline-step::before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 4px solid #ddd;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.timeline-step::after {
  content: '';
  position: absolute;
  width: 25%;
  height: 2px;
  background-color: #ddd;
  top: 50%;
  transform: translateY(-50%);
}

.timeline-step:nth-child(odd)::after {
  left: 100%;
}

.timeline-step:nth-child(even)::after {
  right: 100%;
}

/* Responsive adjustments */
@media (max-width: 1000px) {

  .timeline-content {
    padding: 20px 20px;
  }

  .timeline-step:nth-child(odd) {
    margin-bottom: 40px;
  }
  
  .timeline-step:nth-child(even) {
    margin-bottom: 40px;
  }

  .timeline-step {
    left: 0 !important;
    right: 0 !important;
    margin-bottom: 20px;
  }
  
  .timeline-step:nth-child(odd) {
    left: 0 !important;
    right: 0 !important;
  }

  .timeline-step:nth-child(even) {
    left: 0 !important;
    right: 0 !important;
  }

  .timeline-step:nth-child(5) {
    bottom: auto;
    position: relative;
  }

  .timeline-step::after {
    display: none;
  }
  
  .timeline-step:nth-child(odd)::after {
    display: none;
  }
  
  .timeline-step:nth-child(even)::after {
    display: none;
  }
}

/* Responsive adjustments */
@media (max-width: 600px) {

  .timeline {
    margin-bottom: 30px;
    align-items: center;
    flex-direction: column;
    display: flex;
  }

  .timeline-content {
    padding: 10px 10px;
  }

  .timeline-step {
    width: 90%;
  }
}