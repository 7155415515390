.contact {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.568);
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 5px;
    border: 2px solid #0101019c; 
    box-shadow: inset 0 0 20px rgb(0, 0, 0); 
    margin-bottom: 50px;
    width: 95%;
}

.contact-container-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* Center-align content horizontally */
    gap: 20px; /* Add spacing between the map and the form */
}

.contact-container-map {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}

.contact-container-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center-align content vertically */
    flex: 1; /* Grow to take up remaining space */
    margin: 10px;
}



.contact h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.contact form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact label {
    margin-bottom: 10px;
    width: 100%;
    max-width: 400px;
    text-align: left;
}

.contact input, .contact textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.contact button {
    padding: 10px 20px;
    margin-top: 20px;
    width: 150px;
    border: none;
    border-radius: 5px;
    background-color: #000000;
    color: white;
    cursor: pointer;
}

.contact button:hover {
    background-color: rgba(255, 166, 0, 0.567);
}

.success {
    color: green;
    margin-top: 20px;
}

.error {
    color: red;
    margin-top: 20px;
}

.map-widget {
    width: 700px;
    border: solid;
    border-radius: 5px;
    border-color: white;
    border-width: 2px;
}

@media screen and (max-width: 1150px) {

    .map-widget {
        width: 600px;
    }
}

@media screen and (max-width: 1000px) {

    .map-widget {
        width: 500px;
    }
}


@media screen and (max-width: 900px) {

    .contact-container-main {
        display: flex;
        flex-direction: column;
        justify-content: center; 
        align-items: center;
        text-align: center;
    }

    .map-widget {
        width: 700px;
    }
}

@media screen and (max-width: 770px) {

    .map-widget {
        width: 600px;
    }
}

@media screen and (max-width: 660px) {

    .map-widget {
        width: 500px;
    }
}

@media screen and (max-width: 550px) {

    .map-widget {
        width: 400px;
    }
}


@media screen and (max-width: 440px) {

    .map-widget {
        width: 350px;
    }
}

@media screen and (max-width: 400px) {

    .map-widget {
        width: 350px;
    }

    .contact-container-map {
        margin: 5px;
    }
    .contact {
        padding: 5px;
        margin-left: 0px;
        margin-right: 0px;
        width: 95%;
    }
}

@media screen and (max-width: 400px) {

    .map-widget {
        width: 300px;
    }

    .contact {
        padding: 5px;
        margin-left: 0px;
        margin-right: 0px;
        width: 90%;
    }
}

@media screen and (max-width: 330px) {

    .map-widget {
        width: 280px;
    }
}