/* Add this CSS */
.App-header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000; /* Set a high z-index to ensure it's on top of other content */
  min-height: 8vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: #ffffff; /* Change text color to black */
  border: 2px solid #0101019c; 
  box-shadow: inset 0 0 20px rgb(0, 0, 0); 
}

.App-header-center {
  display: flex;
}

.App-header-black {
  background-color: black;
  color: white; /* Change text color to white */
  transition: background-color 0.3s, color 0.3s; /* Add transition for smooth color change */
}

.nav {
  display: flex;
  align-items: center;
}

.nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.nav ul li {
  margin-left: 1.5rem;
}

.nav ul li a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

/* Add this CSS */
.nav ul li a:hover {
  color: orange;
}

/* Hamburger menu styling */
.hamburger {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 20px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.hamburger .line {
  width: 100%;
  height: 3px;
  background-color: white;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.hamburger.open .line:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger.open .line:nth-child(2) {
  opacity: 0;
}

.hamburger.open .line:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

/* modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.852);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: rgb(0, 0, 0);
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.modal-content h2 {
  margin-top: 0;
}

.modal-content button {
  margin-top: 20px;
  background-color: white;
  height: 40px;
  width: 100px;
  border-radius: 10px;
  cursor: pointer;
}

.modal-header {
  color: white;
  font-size: 30px; /* Adjust the font size as needed */
  margin-top: 20px; /* Adjust the margin as needed */
  margin-bottom: 20px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add a subtle shadow for depth */
  transform: perspective(100px) rotateX(5deg); /* Apply a perspective and rotate effect */
}

.modal-text {
  color: white;
  font-size: 14px;
  margin-bottom: 20px;
}

.modal-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.langselect {
  cursor: pointer;
  background-color: black;
  color: white;
  border: solid;
  border-color: white;
  border-width: 1px;
  margin-left: 5px;
  margin-right: 30px;
  border-radius: 10px;
  padding: 5px;
}

.headertext {
  margin-right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}


/* Responsive styling */
@media (max-width: 768px) {
  .nav {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: #333;
    flex-direction: column;
    align-items: center;
    display: none;
    width: 100%;
  }

  .nav.open {
    display: flex;
  }

  .nav ul {
    flex-direction: column;
  }

  .nav ul li {
    margin: 1rem 0;
  }

  .hamburger {
    display: flex;
  }

  .langselect {
    margin-left: 0px;
    margin-right: 20px;
    margin-bottom: 5px;
  }
}
