.home {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.785);
    color: white;
    min-height: 100vh; /* Ensure the home page takes up full viewport height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .home-header {
    font-size: 2.5rem; /* Adjust the font size as needed */
    margin-top: 80px; /* Adjust the margin as needed */
    margin-bottom: 20px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add a subtle shadow for depth */
    transform: perspective(100px) rotateX(5deg); /* Apply a perspective and rotate effect */
  }

  .home-header-rotate {
    font-size: 1.2rem;
    margin-bottom: 5px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  /* Keyframe animation for each word */
  @keyframes fadeInWords {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  /* Apply animation to each word */
  .home-header-rotate span {
    animation: fadeInWords 0.5s ease-in-out forwards;
    opacity: 0;
    display: inline-block;
    margin-right: 0.5ch; 
  }
  
/* Delay each word's animation */
.home-header-rotate span:nth-child(1) { animation-delay: 0s; }
.home-header-rotate span:nth-child(2) { animation-delay: 0.1s; }
.home-header-rotate span:nth-child(3) { animation-delay: 0.2s; }
.home-header-rotate span:nth-child(4) { animation-delay: 0.3s; }
.home-header-rotate span:nth-child(5) { animation-delay: 0.4s; }
.home-header-rotate span:nth-child(6) { animation-delay: 0.5s; }
.home-header-rotate span:nth-child(7) { animation-delay: 0.6s; }
.home-header-rotate span:nth-child(8) { animation-delay: 0.7s; }
.home-header-rotate span:nth-child(9) { animation-delay: 0.8s; }
.home-header-rotate span:nth-child(10) { animation-delay: 0.9s; }
.home-header-rotate span:nth-child(11) { animation-delay: 1s; }
.home-header-rotate span:nth-child(12) { animation-delay: 1.1s; }
.home-header-rotate span:nth-child(13) { animation-delay: 1.2s; }
.home-header-rotate span:nth-child(14) { animation-delay: 1.3s; }
.home-header-rotate span:nth-child(15) { animation-delay: 1.4s; }
.home-header-rotate span:nth-child(16) { animation-delay: 1.5s; }
.home-header-rotate span:nth-child(17) { animation-delay: 1.6s; }

  
  
  /* Keyframe animation for each word */
  @keyframes fadeInWords {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  /* Apply animation to each word */
  .home-header-rotate span,
  .home-header-rotate-opposite span {
    opacity: 0;
    display: inline-block;
  }
  
  /* Apply animation to the first text */
  .home-header-rotate span {
    animation: fadeInWords 0.5s ease-in-out forwards;
    animation-delay: calc(0.1s * var(--index));
  }
  
  /* Apply animation to the second text */
  .home-header-rotate-opposite span {
    animation: fadeInWordsOpposite 0.5s ease-in-out forwards;
    animation-delay: calc(0.1s * var(--index));
  }
  
  /* Delay each word's animation */
  .home-header-rotate span:nth-child(n) {
    --index: n;
  }
  
  .home-header-rotate-opposite span:nth-child(n) {
    --index: calc(17 - n);
  }
  
  /* Keyframe animation for second text */
  @keyframes fadeInWordsOpposite {
    0% { opacity: 0; transform: translateX(-100%); }
    100% { opacity: 1; transform: translateX(0); }
  }
  

  .rtl-text {
    direction: rtl;
  }
  
  
  

/* MainCatalytics.css */

.responsive-image {
  max-width: 100%; /* Make the image responsive */
  height: auto;
  border-radius: 5px;
  border: solid;
  border-color: rgb(255, 255, 255);
  border-width: 1px;
  margin: 10px;
}

.image-with-effects {
  border-radius: 10px; /* Rounded corners for a frame-like appearance */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow effect */
  transition: transform 0.3s ease-in-out;
}

.image-with-effects:hover {
  transform: scale(1.05); /* Enlarge the image slightly on hover */
}

.paragraph1 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
  border: solid;
  border-width: 1px;
  background-color: rgba(0, 0, 0, 0.568);
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
}

.paragraph2 {
  font-family: 'Times New Roman', Times, serif;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  border: solid;
  border-width: 1px;
  background-color: rgba(0, 0, 0, 0.568);
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
}

.main-catalytics-row {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.main-catalytics-column {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.568);
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
  border: 2px solid #0101019c; 
  box-shadow: inset 0 0 20px rgb(0, 0, 0); 
  margin-bottom: 50px;
  width: 95%;
}

.homesmalltext {
  margin-top: 15px;
}

.countermain {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 15px;
}

.counterbox {
  border: solid;
  border-radius: 100px;
  padding: 20px;
  display: flex;
  width: 150px;
  flex-direction: column;
  justify-content: center;
  border-width: 1px;
  border-color: rgb(255, 255, 255);
  background-color: black;
  margin-left: 30px;
  margin-right: 30px;
  font-family: 'Times New Roman', Times, serif;
  font-size: 18px;

  /* Add 3D effect with shadow */
  box-shadow: 
    0 0 10px rgba(255, 255, 255, 0.5), /* Top highlight */
    0 0 20px rgba(0, 0, 0, 0.8), /* Main shadow */
    inset 0 0 20px rgba(219, 174, 52, 0.481); /* Inner shadow */
}

/* floatingIcon.css */
.floating-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
}

.floating-icon img {
  width: 30px;
  height: 30px;
}

/* floatingIcon.css */
.floating-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #000000;
  border-radius: 50%;
  box-shadow: 0 2px 10px rgba(219, 174, 52, 0.275);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
}

.floating-icon svg {
  color: #ffffff; /* Set the color of the icon */
}





/* Add responsive styles */
@media screen and (max-width: 768px) {
  .homesmalltext {
    margin-top: 10px; /* Adjust margin for smaller screens */
    font-size: 14px; /* Adjust font size for smaller screens */
  }

  .main-catalytics-row {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .paragraph1 {
    font-size: 15px;
  }

  .paragraph2 {
    font-size: 18px;
    margin-top: 12px;
  }

  .counterbox {
    padding: 20px;
    width: 150px;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .homesmalltext {
    margin-top: 5px; /* Further adjust margin for even smaller screens */
    font-size: 12px; /* Further adjust font size for even smaller screens */
  }

  .main-catalytics-column {
    padding: 5px;
    margin-left: 0px;
    margin-right: 0px;
    width: 95%;
  }

  .main-catalytics-row {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .paragraph1 {
    font-size: 15px;
  }

  .paragraph2 {
    margin-top: 20px;
    font-size: 12px;
  }

  .counterbox {
    padding: 15px;
    width: 100px;
    margin-left: 20px;
    margin-right: 20px;
    font-size: 12px;
  }
}

@media screen and (max-width: 400px) {
  .homesmalltext {
    margin-top: 5px; /* Further adjust margin for even smaller screens */
    font-size: 12px; /* Further adjust font size for even smaller screens */
  }

  .main-catalytics-column {
    padding: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: 90%;
  }
}
@media screen and (max-width: 350px) {

  .counterbox {
    padding: 10px;
    width: 100px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 12px;
  }
}