.services {
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .services h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .services-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .service-item {
    background-color: rgb(0, 0, 0);
    border-radius: 10px;
    padding: 20px;
    width: 250px;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.1), 0 10px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.1s, box-shadow 0.1s;
    transform: perspective(500px) rotateX(0) rotateY(0);
    position: relative;
    overflow: hidden;
  }
  
  .service-item:hover {
    box-shadow: 0 8px 16px rgba(242, 180, 74, 0.353);
    cursor: pointer;
    background-color: black;
    font: white;
  }
  
  .service-item h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .service-item p {
    font-size: 1rem;
    color: #666;
  }

  .responsive-image-services {
    max-width: 100%; /* Make the image responsive */
    height: 200px;
    border-radius: 5px;
    border: solid;
    border-color: rgb(255, 255, 255);
    border-width: 1px;
  }

  .services-header {
    font-size: 2.5rem; /* Adjust the font size as needed */
    margin-bottom: 20px;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add a subtle shadow for depth */
    transform: perspective(100px) rotateX(5deg); /* Apply a perspective and rotate effect */
  }
  
  