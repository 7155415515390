.footer {
    min-height: 8vh;
    width: 100%; /* Make the footer take up the available width */
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: calc(6px + 1vmin);
    color: #ffffff; /* Change text color to white */
    box-shadow: inset 0 0 20px rgb(0, 0, 0); 
    background-color: black;
}


.footer-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 0 auto;
}

.footer-section {
    flex: 1;
    margin: 10px;
    min-width: 250px;
}

.footer-section h3 {
    margin-bottom: 15px;
}

.footer-section p, .footer-section ul, .footer-section .social-icons {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footer-section ul li {
    margin-bottom: 10px;
}

.footer-section ul li a {
    color: #fff;
    text-decoration: none;
}

.footer-section ul li a:hover {
    text-decoration: underline;
}

.social-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
}

.social-icons a {
    color: #fff;
    font-size: 1.5em;
    margin: 0 10px;
    text-decoration: none;
}

.social-icons a:hover {
    color: #aaa;
}

.footer-bottom {
    margin-top: 20px;
    border-top: 1px solid #444;
    padding-top: 10px;
    width: 100%;
}

.law-firm-logo {
    width: 100px;
    height: 100px; 
    border-radius: 100px;
}

.footer-section.documents ul {
    list-style: none;
    padding: 0;
}

.footer-section.documents ul li {
    margin: 5px 0;
}

.footer-section.documents ul li a {
    text-decoration: none;
    color: inherit; /* Match the color with the rest of the footer text */
}

.footer-section.documents ul li a:hover {
    text-decoration: underline;
}

/* In your footer.css or equivalent CSS file */
.footer .footer-section a {
    color: inherit; /* Ensure the link color matches the surrounding text */
    text-decoration: none; /* Remove underline */
}

.footer .footer-section a:hover {
    text-decoration: underline; /* Optional: Add underline on hover */
}


