.App {
  text-align: center;
}

body {
  background-image: url('/public/bgblue.png');
  background-size: cover; /* Adjusts the background image to cover the entire element */
  background-position: center; /* Centers the background image */
  background-attachment: fixed; /* Keeps the background image fixed during scrolls */
}




.App-logo {
  height: 28px;
  pointer-events: none;
}

.App-header {
  background-color: rgba(0, 0, 0, 0.785);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
